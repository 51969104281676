/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.mobile-menu').click(function () {
            $('nav.nav-primary').toggleClass('active');
            $('footer#nav-footer').toggleClass('active');
        });
        $('nav.nav-primary ul li').click(function() {
            $(this).children('div').children('ul').toggleClass('active');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Javascript for video comparison
        var videoContainer = document.getElementById("video-compare-container"),
            videoClipper = document.getElementById("video-clipper"),
            clippedVideo = videoClipper.getElementsByTagName("video")[0];
        function trackLocation(e) {
            var rect = videoContainer.getBoundingClientRect(),
                position = ((e.pageX - rect.left) / videoContainer.offsetWidth)*100;
                video_1 = document.getElementById('video-1');
            if ((position <= 100) && (!video_1.paused)) { 
                videoClipper.style.width = position+"%";
                clippedVideo.style.width = ((100/position)*100)+"%";
                clippedVideo.style.zIndex = 3;
            }
        }
        videoContainer.addEventListener("mousemove", trackLocation, false); 
        videoContainer.addEventListener("touchstart",trackLocation,false);
        videoContainer.addEventListener("touchmove",trackLocation,false);
        
        // Javascripot for playing/pausing the video player
        function playPauseVideo() {
            var video_1 = document.getElementById('video-1');
            var video_2 = document.getElementById('video-2');
            if (video_1.paused) {
                video_1.play();
                video_2.play();
            } else {
                video_1.pause();
                video_2.pause();
            }
        }
        $("#video-compare-container").add("#content.home .play-button")
        .click( function() {
            playPauseVideo();
            $("#content.home .play-button").hide();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Services page.
    'servicios': {
      init: function() {
        // JavaScript to be fired on the Servicios page
        
        // Accordion effect for Servicios
        $(".services article .service-content").hide();
        $(".services article h4").click(function () { 
            if ($(this).hasClass("active")) {
                // Minimize current service
                $(this).removeClass("active");
                $(this).next().slideUp('fast');
                return;
            }
            // Minimize all services
            $(".services article h4").removeClass("active");
            $(".services article .service-content").slideUp('fast');
            // Maximize current service
            $(this).addClass("active");
            $(this).next().slideDown('fast');
	});
      }
    },
    // Services page.
    'proyectos': {
      init: function() {
        // JavaScript to be fired on the Proyectos page
        // Vimeo popup using Magnific Popup
        $('.project').magnificPopup({
            delegate: 'a', // child items selector, by clicking on it popup will open
            type: 'iframe',
            iframe: {
                markup: '<div class="mfp-iframe-scaler">'+
                           '<div class="mfp-close"></div>'+
                           '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                           '<div class="mfp-title"></div>'+
                         '</div>'
            },
            callbacks: {
                markupParse: function(template, values, item) {
                    values.title = item.el.find('.video-title').html();
                }
            },
            gallery:{
                enabled:true
            }
            // other options
        });
      }
    },
    // Blog.
    'blog': {
      init: function() {
        // JavaScript to be fired on the Blog page
        $('.window-share').click(function(e) {
            e.preventDefault();
            window.open($(this).attr('href'), 'ShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            return false;
        });
      }
    },
    'single_post': {
      init: function() {
        // JavaScript to be fired on the Blog page
        $('.window-share').click(function(e) {
            e.preventDefault();
            window.open($(this).attr('href'), 'ShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            return false;
        });
      }
    },
    'instalaciones': {
      init: function() {
        // JavaScript to be fired on the Instalaciones page
        $('.owl-carousel').owlCarousel({
            items:1,
            nav:true
        });
        // Show/hide room info when clicking the icon
        $(".rooms .studio-info .info-text").hide();
        $(".rooms .studio-info .info-icon").click(function () { 
            $(this).parent().find('.info-text').toggle(200);
	});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function($) {
// Resize main page section
$(window).load(function() {
    var theWindow  = $(window),
        contents   = $(".resize"),
        old_width  = 999999;

    function resizeContent() {
        contents.each(function() {
            // General resize: we substract the header
            $(this).height(theWindow.height() - 35);
            // We want a container for the image that will fit its width as well
            if ($(this).hasClass('img-container')) {
                if (theWindow.width() <= old_width) {
                    $(this).width($(this).children('img').width());
                } else {
                    $(this).width('auto');
                    $(this).width($(this).children('img').width());
                }
            }
            // We want the image to adapt to the main resized container
            if ($(this).children('img').hasClass('img-resize')) {
                var img = $(this).children('img');
                imgRatio = img.width() / img.height();
                if (((theWindow.width() - 186) / (theWindow.height() - 35)) < imgRatio) {
		    img
		    	.removeClass('full-height full-width')
		    	.addClass('full-height');
		} else {
		    img
		    	.removeClass('full-height full-width')
		    	.addClass('full-width');
		}
            }
        });
        old_width = theWindow.width();
    }

    theWindow.resize(resizeContent).trigger("resize");
});
})(jQuery);